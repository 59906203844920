let price = {
    "Sole Trader": {
        "Up to £90k": {
            "accounting_transactions_per_month": {
                "Up to 20": 30,
                "20-40": 40,
                "40-80": 50,
                "80-120": 55,
                "120+": 60,
                "I don't know": 30
            },
            "vat_transactions_per_month": {
                "Up to 20": 50,
                "20-40": 55,
                "40-80": 60,
                "80-120": 65,
                "120+": 70,
                "I don't know": 50
            },
            "payroll_how_many_employee": 10,
            "pension_how_many_employee": 25
        },
        "£90-200k": {
            "accounting_transactions_per_month": {
                "Up to 20": 35,
                "20-40": 45,
                "40-80": 55,
                "80-120": 60,
                "120+": 65,
                "I don't know": 35
            },
            "vat_transactions_per_month": {
                "Up to 20": 55,
                "20-40": 60,
                "40-80": 65,
                "80-120": 70,
                "120+": 75,
                "I don't know": 55
            },
            "payroll_how_many_employee": 15,
            "pension_how_many_employee": 30
        },
        "£200-500k": {
            "accounting_transactions_per_month": {
                "Up to 20": 40,
                "20-40": 50,
                "40-80": 60,
                "80-120": 65,
                "120+": 70,
                "I don't know": 40
            },
            "vat_transactions_per_month": {
                "Up to 20": 60,
                "20-40": 65,
                "40-80": 70,
                "80-120": 75,
                "120+": 80,
                "I don't know": 60
            },
            "payroll_how_many_employee": 20,
            "pension_how_many_employee": 35
        },
        "£500k-1m": {
            "accounting_transactions_per_month": {
                "Up to 20": 45,
                "20-40": 55,
                "40-80": 65,
                "80-120": 70,
                "120+": 75,
                "I don't know": 45
            },
            "vat_transactions_per_month": {
                "Up to 20": 65,
                "20-40": 70,
                "40-80": 75,
                "80-120": 80,
                "120+": 85,
                "I don't know": 65
            },
            "payroll_how_many_employee": 25,
            "pension_how_many_employee": 40
        },
        "£1m+": {
            "accounting_transactions_per_month": {
                "Up to 20": 50,
                "20-40": 60,
                "40-80": 70,
                "80-120": 75,
                "120+": 85,
                "I don't know": 50
            },
            "vat_transactions_per_month": {
                "Up to 20": 70,
                "20-40": 75,
                "40-80": 80,
                "80-120": 85,
                "120+": 90,
                "I don't know": 70
            },
            "payroll_how_many_employee": 30,
            "pension_how_many_employee": 45
        }
    },
    "Limited Company": {
        "Up to £90k": {
            "accounting_transactions_per_month": {
                "Up to 20": 100,
                "20-40": 105,
                "40-80": 110,
                "80-120": 115,
                "120+": 120,
                "I don't know": 100
            },
            "vat_transactions_per_month": {
                "Up to 20": 50,
                "20-40": 55,
                "40-80": 60,
                "80-120": 65,
                "120+": 70,
                "I don't know": 50
            },
            "payroll_how_many_employee": 10,
            "pension_how_many_employee": 25
        },
        "£90-200k": {
            "accounting_transactions_per_month": {
                "Up to 20": 105,
                "20-40": 110,
                "40-80": 115,
                "80-120": 120,
                "120+": 125,
                "I don't know": 105
            },
            "vat_transactions_per_month": {
                "Up to 20": 55,
                "20-40": 60,
                "40-80": 65,
                "80-120": 70,
                "120+": 75,
                "I don't know": 55
            },
            "payroll_how_many_employee": 15,
            "pension_how_many_employee": 30
        },
        "£200-500k": {
            "accounting_transactions_per_month": {
                "Up to 20": 110,
                "20-40": 115,
                "40-80": 120,
                "80-120": 125,
                "120+": 130,
                "I don't know": 110
            },
            "vat_transactions_per_month": {
                "Up to 20": 60,
                "20-40": 65,
                "40-80": 70,
                "80-120": 75,
                "120+": 80,
                "I don't know": 60
            },
            "payroll_how_many_employee": 20,
            "pension_how_many_employee": 35
        },
        "£500k-1m": {
            "accounting_transactions_per_month": {
                "Up to 20": 115,
                "20-40": 120,
                "40-80": 125,
                "80-120": 130,
                "120+": 135,
                "I don't know": 115
            },
            "vat_transactions_per_month": {
                "Up to 20": 65,
                "20-40": 70,
                "40-80": 75,
                "80-120": 80,
                "120+": 85,
                "I don't know": 65
            },
            "payroll_how_many_employee": 25,
            "pension_how_many_employee": 40
        },
        "£1m+": {
            "accounting_transactions_per_month": {
                "Up to 20": 120,
                "20-40": 125,
                "40-80": 130,
                "80-120": 135,
                "120+": 140,
                "I don't know": 120
            },
            "vat_transactions_per_month": {
                "Up to 20": 70,
                "20-40": 75,
                "40-80": 80,
                "80-120": 85,
                "120+": 90,
                "I don't know": 70
            },
            "payroll_how_many_employee": 30,
            "pension_how_many_employee": 45
        }
    }
}

// const calculatePrice = (company) => {
//     try {
//         let pricePlan = price[company.business_type][company.annual_income]

//         let totoalPrice = 0
//         if (company.interested_service.accounting_and_bookkeeping) {
//             totoalPrice += pricePlan['accounting_transactions_per_month'][company.accounting_transactions_per_month]
//         }

//         if (company.interested_service.vat_returns) {
//             totoalPrice += pricePlan['vat_transactions_per_month'][company.vat_transactions_per_month]
//         }

//         if (company.interested_service.payroll) {
//             totoalPrice += (pricePlan['payroll_how_many_employee'] * company.payroll_how_many_employee)
//         }

//         if (company.interested_service.pension) {
//             totoalPrice += (pricePlan['pension_how_many_employee'] * company.pension_how_many_employee)
//         }
//         return totoalPrice;
//     } catch (err) {
//         return 0
//     }
// }

const calculatePrice = (company) => {
    let totalPrice = 0
    let servicePrice = null
    switch (company.business_type) {
        case 'Limited Company':
            servicePrice = calculateLimited(company)
            break;
        case 'Sole Trader':
            servicePrice = calculateSole(company)
            break;
        case 'Dormant':
            servicePrice = calculateDormant(company)
            break;
        case 'SPV':
            servicePrice = calculateSpv(company)
            break;
        default:
            break;
    }
    // { totalPrice, service_fees }
    return servicePrice
}

const calculateLimited = (company) => {
    try {
        let totalPrice = 0
        let data = {
            "Up to £90k": {
                "accounting_option": {
                    "Up to 40": 100,
                    "40-120": 110,
                    "120+": 120,
                    "No Bookkeeping": 50
                },
                "vat_returns": {
                    "standard": 50,
                    "enterprise": 80,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 30,
                    "Up to 1000": 60,
                    "Up to 5000": 120,
                }
            },
            "£90-£500k": {
                "accounting_option": {
                    "Up to 40": 110,
                    "40-120": 120,
                    "120+": 130,
                    "No Bookkeeping": 60
                },
                "vat_returns": {
                    "standard": 55,
                    "enterprise": 85,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 40,
                    "Up to 1000": 70,
                    "Up to 5000": 130,
                }
            },
            "£500k-£1m": {
                "accounting_option": {
                    "Up to 40": 120,
                    "40-120": 130,
                    "120+": 140,
                    "No Bookkeeping": 70
                },
                "vat_returns": {
                    "standard": 60,
                    "enterprise": 90,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 50,
                    "Up to 1000": 80,
                    "Up to 5000": 140,
                }
            },
            "£1m+": {
                "accounting_option": {
                    "Up to 40": 130,
                    "40-120": 140,
                    "120+": 150,
                    "No Bookkeeping": 80
                },
                "vat_returns": {
                    "standard": 65,
                    "enterprise": 95,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 60,
                    "Up to 1000": 90,
                    "Up to 5000": 150,
                }
            }
        }

        data = data[company.annual_income]

        let service_fees = {
            accounting_option: 0,
            vat_returns: 0,
            payroll: 0,
            pension: 0,
            ecommerce_accounting: 0
        }

        totalPrice += data.accounting_option[company.accounting_option]
        service_fees.accounting_option = data.accounting_option[company.accounting_option]

        if (company?.interested_service?.vat_returns) {
            if (totalPrice >= data.accounting_option['120+']) {
                totalPrice += data.vat_returns.enterprise
                service_fees.vat_returns = data.vat_returns.enterprise
            } else {
                totalPrice += data.vat_returns.standard
                service_fees.vat_returns = data.vat_returns.standard
            }
        }
        if (company?.interested_service?.payroll) {
            let totalPayrol = company.payroll_option - 1 || 0
            if (totalPayrol > 0) {
                totalPrice += data.payroll
                service_fees.payroll = data.payroll
            }
        }

        if (company?.interested_service?.pension) {
            totalPrice += data.pension
            service_fees.pension = data.pension
        }

        if (company?.interested_service?.ecommerce_accounting) {
            totalPrice += data.ecommerce_accounting[company.ecommerce_option]
            service_fees.ecommerce_accounting = data.ecommerce_accounting[company.ecommerce_option]
        }
        return { totalPrice, service_fees };
    } catch (err) {
        console.log(err);
        return 0
    }
}

const calculateSole = (company) => {
    try {
        let totalPrice = 0
        let data = {
            "Up to £90k": {
                "accounting_option": {
                    "Bookkeeping included": 100,
                    "No bookkeeping": 40,
                },
                "vat_returns": {
                    "standard": 50,
                    "enterprise": 80,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 30,
                    "Up to 1000": 60,
                    "Up to 5000": 120,
                }
            },
            "£90-£500k": {
                "accounting_option": {
                    "Bookkeeping included": 110,
                    "No bookkeeping": 50,
                },
                "vat_returns": {
                    "standard": 55,
                    "enterprise": 85,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 40,
                    "Up to 1000": 70,
                    "Up to 5000": 130,
                }
            },
            "£500k-£1m": {
                "accounting_option": {
                    "Bookkeeping included": 120,
                    "No bookkeeping": 60,
                },
                "vat_returns": {
                    "standard": 60,
                    "enterprise": 90,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 50,
                    "Up to 1000": 80,
                    "Up to 5000": 140,
                }
            },
            "£1m+": {
                "accounting_option": {
                    "Bookkeeping included": 130,
                    "No bookkeeping": 70,
                },
                "vat_returns": {
                    "standard": 65,
                    "enterprise": 95,
                },
                "payroll": 25,
                "pension": 25,
                "ecommerce_accounting": {
                    "Up to 200": 60,
                    "Up to 1000": 90,
                    "Up to 5000": 150,
                }
            }
        }

        data = data[company.annual_income]
        let service_fees = {
            accounting_option: 0,
            vat_returns: 0,
            payroll: 0,
            pension: 0,
            ecommerce_accounting: 0
        }

        totalPrice += data.accounting_option[company.accounting_option]
        service_fees.accounting_option = data.accounting_option[company.accounting_option]
        if (company?.interested_service?.vat_returns) {
            totalPrice += data.vat_returns.standard
            service_fees.vat_returns = data.vat_returns.standard
        }
        if (company?.interested_service?.payroll) {
            let totalPayrol = company.payroll_option - 1 || 0
            if (totalPayrol > 0) {
                totalPrice += data.payroll
                service_fees.payroll = data.payroll
            }
        }
        if (company?.interested_service?.pension) {
            totalPrice += data.pension
            service_fees.pension = data.pension
        }

        if (company?.interested_service?.ecommerce_accounting) {
            totalPrice += data.ecommerce_accounting[company.ecommerce_option]
            service_fees.ecommerce_accounting = data.ecommerce_accounting[company.ecommerce_option]
        }

        return { totalPrice, service_fees };

    } catch (err) {
        console.log(err);
        return 0
    }
}

const calculateDormant = (company) => {
    let service_fees = {
        accounting_option: 0,
        vat_returns: 0,
        payroll: 0,
        pension: 25,
        ecommerce_accounting: 0
    }
    let totalPrice = 25
    return { totalPrice, service_fees }
}

const calculateSpv = (company) => {
    try {
        let totalPrice = 0
        let data = {
            "Up to £90k": {
                "accounting_option": {
                    "0": 25,
                    "1": 67,
                    "2": 54,
                },
                "vat_returns": {
                    "standard": 50,
                    "enterprise": 80,
                },
                "payroll": 25,
                "pension": 25
            },
            "£90-£500k": {
                "accounting_option": {
                    "0": 25,
                    "1": 67,
                    "2": 54,
                },
                "vat_returns": {
                    "standard": 55,
                    "enterprise": 85,
                },
                "payroll": 25,
                "pension": 25
            },
            "£500k-£1m": {
                "accounting_option": {
                    "0": 25,
                    "1": 67,
                    "2": 54,
                },
                "vat_returns": {
                    "standard": 60,
                    "enterprise": 90,
                },
                "payroll": 25,
                "pension": 25
            },
            "£1m+": {
                "accounting_option": {
                    "0": 25,
                    "1": 67,
                    "2": 54,
                },
                "vat_returns": {
                    "standard": 65,
                    "enterprise": 95,
                },
                "payroll": 25,
                "pension": 25
            }
        }

        data = data[company.annual_income]
        let service_fees = {
            accounting_option: 0,
            vat_returns: 0,
            payroll: 0,
            pension: 0,
            ecommerce_accounting: 0
        }

        if (company.accounting_option == 0) {
            totalPrice += 25
            service_fees.accounting_option = 25
        } else if (company.accounting_option == 1) {
            totalPrice += 67
            service_fees.accounting_option = 67
        } else if (company.accounting_option >= 2) {
            totalPrice += (company.accounting_option * 54)
            service_fees.accounting_option = (company.accounting_option * 54)
        }

        if (company?.interested_service?.vat_returns) {
            totalPrice += data.vat_returns.standard
            service_fees.vat_returns = data.vat_returns.standard
        }

        if (company?.interested_service?.payroll) {
            let totalPayrol = company.payroll_option - 1 || 0
            if (totalPayrol > 0) {
                totalPrice += data.payroll
                service_fees.payroll = data.payroll
            }
        }
        if (company?.interested_service?.pension) {
            totalPrice += data.pension
            service_fees.pension = data.pension
        }

        return { totalPrice, service_fees };

    } catch (err) {
        console.log(err);
        return 0
    }
}



export default calculatePrice